<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $ml.get("setting.connection") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$ml.get('setting.connection') + '*'"
                  v-model="name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$ml.get('setting.api_server') + '*'"
                  v-model="server"
                  :rules="serverRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
          <v-btn v-if="name" color="primary" text @click="store">{{
            $ml.get("button.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      dialog: true,
      thisparent: null,
      name: "",
      id: 0,
      server: "",
      nameRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
      serverRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
    };
  },
  methods: {
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (!this.name) return;
      let url = "/zone/add";
      let data = {
        name: this.name,
        data: "{}",
        parent_id: this.thisparent.id,
      };
      // if (this.thisparent) {
      //   data.parent_id = this.thisparent.id;
      // }
      if (this.id) {
        data.id = this.id;
        url = "/zone/" + this.id;
      }
      this.axios
        .post(url, data, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          if (response.status == 200) {
            this.close();
          }
        });
    },
  },
  mounted() {
    this.id = this.value.id;
    this.name = this.value.name;
    this.server = this.value.server + ":" + this.value.port;
    //this.zones.find()
  },
};
</script>
